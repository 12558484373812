import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import hotIocn from "../../images/hot-icon.png";
// @ts-ignore
import banner from "../../images/banner.jpg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ITEM_PERFECT_INLINED } from "../../constants/style";
import VmButton from "../shared-button";

const PackageCard = observer(({
  packagePrice, title, description, imgSrc, className = "", onClickView }: {
    packagePrice: string,
    title: string,
    description: string,
    imgSrc: string,
    className?: string,
    onClickView: () => {}
  }) => {
  const { t }: any = useTranslation();

  return (
    <button onClick={onClickView} className={`shadow-lg text-left border rounded-sm bg-white cursor-pointer hover:shadow-sm ${className} transition-all`}>
      <Box className="h-70 relative">
        {/* <img src={hotIocn} className="absolute scale-25 2xl:scale-50 -left-20 -top-3/4 2xl:-left-16 2xl:-top-1/2" /> */}
        <img src={imgSrc} className="h-full w-full object-cover" />
        <Box className="flex justify-end absolute  w-full" style={{ right: 10, bottom: 10 }}>
          <Box className="bg-sky-400 text-white text-base py-1 px-3 rounded-md">{t('FROM')} A${packagePrice} {t('FROM_B')}</Box>
        </Box>
      </Box>
      <Box className="p-2 h-40">
        <p className="lg:text-sm 2xl:text-lg mb-2 uppercase">{title}</p>
        <p className="lg:text-xs 2xl:text-sm font-light">{description}</p>
      </Box>
    </button>
  )
});

export default PackageCard;
