import React, { useEffect, useState } from "react"
import Layout from "./shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import VmButton from "./shared-button";
import VmStepperTitle from "./shared-stepper-title";
// @ts-ignore
import EnjoysAus from "../images/enjoyaus.jpg";
import SendIcon from '@mui/icons-material/Send';

const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";

const GPT_MODEL = "gpt-3.5-turbo";//use gpt-4 in the future

const API_KEY = "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";// Steve's free key

const ChatGPTPage = observer((props: any) => {
  const { placeNames, setPlaceNames } = props
  const { t }: any = useTranslation();
  const systemMessagePlaceName = [
    { "role": "system", "content": "You are a text processing program and just need to return the results." },
    { "role": "user", "content": `I want to go to Melbourne for 3 day, I want to taste the food, go to enjoy the natural attractions and am interested in history, help me plan. Would love to stay in a 5 star hotel. Can you please return a JSON code in the format as this 
    {
      allRecommenedPlaces: ["A"],
      dayTrip: [
       [{ name: "A", description: "This is area A" }], 
      ]
    }
    Store all recommended places into the "allRecommenedPlaces" field in the above JSON. And only arrange these places by trip day with relevant descriptions and store into "dayTrip" from the recommended places list not others.` },
  ]
  useEffect(() => {
    getPlaceNames();
  }, []);

  const getPlaceNames = async () => { // get place names in JSON format from the last answer.
    const apiRequestBodyForJson = {
      "model": GPT_MODEL,
      "temperature": 0,
      "messages": [
        { "role": "system", "content": "You are a text processing program and just need to return the results." },
        { "role": "user", "content": `I want to go to Melbourne for 3 day, I want to taste the food, go to enjoy the natural attractions and am interested in history, help me plan. Would love to stay in a 5 star hotel. Can you please return a JSON code in the format as this 
        { allRecommenedPlaces: ["A"],
          dayTrip: [
           [{ name: "A", description: "This is area A" }], 
          ] } Store all recommended places into the "allRecommenedPlaces" field in the above JSON. And only arrange these places by trip day with relevant descriptions and store into "dayTrip" from the recommended places list not others.` },
      ],
    }
    // return;
    try {
      console.log(apiRequestBodyForJson);
      await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBodyForJson)
      }).then((data) => {
        return data.json();
      }).then((data) => {
      })
    } catch {
    }
  }

  return (
    <>
    </>
  );
})

export default ChatGPTPage;
