import React, { useEffect, useState } from "react"
import Layout from "../components/shared-layout";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SceneSpotCard from "../components/home-page/scene-spot-card";
import PackageCard from "../components/home-page/package-card";
// @ts-ignore
import SnowMoutain from "../images/snow-mountain.jpg";
import { PADDING_HORIZONTAL } from "../constants/style";
import AccommodationCard from "../components/home-page/accommodation-card";
import useStores from "../hooks/use-stores";
import { ContentType } from "../constants/options";
import VmButton from "../components/shared-button";
import { navigate } from "gatsby";
import { GetLanguage } from "../utilities/general";
import ChatGPTPage from "../components/chatGPT";

const SectionHeading = observer(({ titleColored, title, children, color }: { titleColored: string, title: string, children: any, color?: "default" | "invert" }) => {
  return (
    <Box textAlign="center" my={4}>
      <p className="text-xl sm:text-2xl 2xl:text-3xl text-sky-400 uppercase font-bold mt-4">{titleColored} <span className="text-black">{title}</span></p>
      <div className="text-6xl" style={{ marginTop: -15, marginBottom: -10 }}><ArrowDropDownIcon fontSize="inherit" color={color == "invert" ? "info" : "black"} /></div>
      <p className="text-sm sm:texl-base 2xl:text-lg">{children}</p>
    </Box>
  )
});

const IndexPage = observer(() => {
  const { productStore } = useStores();
  const { t }: any = useTranslation();

  useEffect(() => {
    if (productStore.promotionList.length == 0) productStore.getPromotionProductList(ContentType.Promotion, GetLanguage());
    if (productStore.popularList.length == 0) productStore.getPromotionProductList(ContentType.Popular, GetLanguage());
    if (productStore.newPromotionList.length == 0) productStore.getPromotionProductList(ContentType.New, GetLanguage());
  }, []);

  return (
    <Layout pageName={t('HOME')}>
      <ChatGPTPage />
      {/* Start Recommened Scenic Spot */}
      <Box className={PADDING_HORIZONTAL}>
        <SectionHeading titleColored={t('FRESHLY')} title={t('HAND_PICKED')}>
          {t('NEW_PROMOTION_LIST_DESCRIPTION')}
        </SectionHeading>
        <Box my={2}>
          <Box className="grid grid-cols-1 sm:grid-cols-2 sm:gap-4 2xl:gap-8">
            <Box className="overflow-hidden">
              {productStore.newPromotionList.length > 0 &&
                <SceneSpotCard
                  className="sm:mb-4 2xl:mb-8 h-52 sm:h-60 2xl:h-80"
                  title={productStore.newPromotionList[0].searchTerms}
                  description={productStore.newPromotionList[0].webGrabline.substring(0, 40) + "..."}
                  imgSrc={productStore.newPromotionList[0].webThumb}
                  onClickView={() => navigate(`/product-detail?productId=${productStore.newPromotionList[0].tid}`, { state: { prevPath: '/' } })}
                />
              }
              {productStore.newPromotionList.length > 1 &&
                <SceneSpotCard
                  className="h-52 sm:h-60 2xl:h-80"
                  title={productStore.newPromotionList[1].searchTerms}
                  description={productStore.newPromotionList[1].webGrabline.substring(0, 40) + "..."}
                  imgSrc={productStore.newPromotionList[1].webThumb}
                  onClickView={() => navigate(`/product-detail?productId=${productStore.newPromotionList[1].tid}`, { state: { prevPath: '/' } })}
                />}
            </Box>
            {productStore.newPromotionList.length > 2 &&
              <SceneSpotCard
                className="h-52 sm:h-full"
                title={productStore.newPromotionList[2].searchTerms}
                description={productStore.newPromotionList[2].webGrabline.substring(0, 80) + "..."}
                imgSrc={productStore.newPromotionList[2].webThumb}
                position={"Bottom Right"}
                onClickView={() => navigate(`/product-detail?productId=${productStore.newPromotionList[2].tid}`, { state: { prevPath: '/' } })}
              />}
          </Box>
        </Box>
      </Box>
      {/* End Recommened Scenic Spot */}
      {/* Start Get Accommodation */}
      <>
        {productStore.promotionList.length > 0 &&
          <Box className="hidden sm:block mt-8 relative overflow-hidden h-30vh lg:h-50vh">
            <img src={productStore.promotionList[0].webThumb} className="w-full h-full object-cover" style={{ filter: "opacity(0.3) blur(5px)" }} />
            <div className={`sm:absolute w-full left-0 top-1/2 grid grid-cols-1 sm:grid-cols-2 gap-6 ${PADDING_HORIZONTAL}`}
              style={{ transform: 'translate(0, -50%)' }}>
              <Box className="col-span-2 lg:col-span-1 relative">
                <Box className="absolute top-1/2 left-0 translate-y-0 md:translate-y-neg-1/2">
                  <p className="text-base 2xl:text-lg">{t('ACTIVITY')} <span className="text-sky-400">{t('MEANINGFUL_ACTIVITIES')}</span></p>
                  <p className="mt-4 lg:text-xl 2xl:text-2xl">{productStore.promotionList[0].searchTerms}</p>
                  <p className="mt-8 lg:text-sm 2xl:text-base text-gray-500">{productStore.promotionList[0].webGrabline}</p>
                  <VmButton className="mt-8 px-10" onClick={() => navigate(`/product-detail?productId=${productStore.promotionList[0].tid}`, { state: { prevPath: '/' } })}>
                    {t('MORE')}
                  </VmButton>
                </Box>
              </Box>
              <Box className="col-span-2 lg:col-span-1 hidden lg:flex justify-center">
                <img src={productStore.promotionList[0].webThumb}
                  className="w-full sm:w-4/5 h-80 object-cover brightness-75" />
              </Box>
            </div>
          </Box>}
      </>
      {/* End Get Accommodation */}
      {/* Start Preferntial Pacakge Recommendation */}
      <Box className={PADDING_HORIZONTAL}>
        <SectionHeading titleColored={t('MORE_BANG')} title={t('FOR_YOUR_BUCK')}>
          {t('POPULAR_LIST_DESCRIPTION')}
        </SectionHeading>
        <Box my={2}>
          <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {productStore.popularList.map((pkg: any, index: number) => (
              <PackageCard
                key={`popular_index_${index}`}
                packagePrice={pkg.webPrice}
                title={pkg.searchTerms}
                description={pkg.webGrabline ? pkg.webGrabline.substring(0, 100) + "..." : ""}
                imgSrc={pkg.webThumb}
                onClickView={() => navigate(`/product-detail?productId=${pkg.tid}`, { state: { prevPath: '/' } })}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {/* End Preferntial Pacakge Recommendation */}
    </Layout>
  )
});

export default IndexPage;
