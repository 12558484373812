import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import banner from "../../images/banner.jpg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ITEM_PERFECT_INLINED } from "../../constants/style";
import VmButton from "../shared-button";;

const SceneSpotCard = observer(({
  position = "Top Left", title, description, imgSrc, className = "", onClickView }: {
    position?: "Top Left" | "Bottom Right",
    title: string,
    description: string,
    imgSrc: string,
    className?: string,
    onClickView: () => {}
  }) => {
  const { t }: any = useTranslation();
  const productImgStyle = "brightness-75 w-full h-full object-cover";
  const [buttonVisibility, setButtonVisibility] = useState<boolean>(false);
  const onToggleButtonVisibility = (v: boolean) => setButtonVisibility(v);

  return (
    <div className={`relative w-full h-full overflow-hidden ${className}`}>
      <button onClick={onClickView} className="w-full h-full">
      <img className={`${productImgStyle} w-full h-full hover:scale-110 transition duration-500`} src={imgSrc} />
      <div className={`absolute left-0 ${position === "Top Left" ? "top-0 text-left" : "bottom-0 text-right"} w-full p-4 text-white`}>
        <p className="lg:text-base 2xl:text-xl font-bold mb-2">{title}</p>
        <p className="lg:text-sm 2xl:text-lg text-sm font-light">{description}</p>
        <span className={`block ${position == "Bottom Right" && "float-right"} bg-white w-10 h-1 rounded-md mt-4`} />
      </div>
      {/* <div className={`absolute top-0 left-0 right-0 bottom-0 transition`}
        onMouseEnter={() => onToggleButtonVisibility(true)}
        onMouseLeave={() => onToggleButtonVisibility(false)}
      >
        {buttonVisibility &&
          <VmButton
            rounded={"none"}
            disabledHover
            className={`m-4 absolute ${position === "Top Left" ? "bottom-0 right-0" : "top-0 left-0"}`}>
            <Box sx={ITEM_PERFECT_INLINED}>
              <Typography mr={1} className="lg:text-sm 2xl:text-lg 2xl:mt-1">{t('VIEW')}</Typography>
              <span className="lg:text-sm 2xl:text-xl"><ArrowForwardIosIcon style={{ marginRight: -5 }} fontSize="inherit" /></span>
            </Box>
          </VmButton>}
      </div> */}
      </button>
    </div>
  )
});

export default SceneSpotCard;
